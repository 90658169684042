<template>
    <div class="rj_box container">
        <div class="rj_down_box">
            <div>
                <div class="rj_down" slot="reference">
                    <img src="@assets/pc/rj1.png"/>
                    <div>牛牛智投</div>
                    <div class="opera_box">
                        <a href="https://130.qzdxm.cn/牛牛智投.msi">
                            <img src="@assets/pc/rj_down.png"/>
                            快速下载
                        </a>
                        <span class="ml">
                            <img src="@assets/pc/concat.png"/>
                            联系软件方
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div class="rj_down" slot="reference">
                    <img src="@assets/pc/rj2.png"/>
                    <div>牛牛跟单</div>
                    <div class="opera_box">
                        <span>
                            <img src="@assets/pc/rj_down.png"/>
                            暂未开放
                        </span>
                        <span class="ml">
                            <img src="@assets/pc/concat.png"/>
                            联系软件方
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div class="rj_down" slot="reference">
                    <img src="@assets/pc/rj3.png"/>
                    <div>牛牛计划</div>
                    <div class="opera_box">
                        <span>
                            <img src="@assets/pc/rj_down.png"/>
                            暂未开放
                        </span>
                        <span class="ml">
                            <img src="@assets/pc/concat.png"/>
                            联系软件方
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="rj_cont">
            <div class="rj_title">软件简介</div>
            <div class="rj_main">
                <!-- <div class="rj_tab">
                    <div class="tab_active">PC端</div>
                    <div>APP</div>
                </div> -->
                <div class="rj_text" style="padding-top: 46px;">
                    <div>全平台覆盖，跨平台数据共享</div>
                    <div>一个账号登录，所有设置一键同步，喜欢的功能无需重复设置； 云端同步，数据永不丢失。</div>
                </div>
                <div class="rj_text">
                    <div>算法全面升级，带来极致体验</div>
                    <div>牛牛智投采用行业最新技术，算法及性能更强，速度更快，占用内存更低，跟投更智能</div>
                </div>
                <div class="rj_text">
                    <div>新连接、全场景、更智能</div>
                    <div>连接各大彩种及最新资讯，彩种全、出彩快</div>
                </div>
                <div class="rj_img_text">
                    <img src="@assets/pc/rj_intro1.png"/>
                    <span>牛牛智投_投注页</span>
                </div>
            </div>
        </div>
        <div v-show="is_show" class="concat_box">
            <div class="title">
                联系我们
                <span @click="close">×</span>
            </div>
            <div class="cont">
                <div class="concat_row">
                    <span>qq:</span>
                    <div>454545</div>
                </div>
            </div>
        </div>
        <div v-show="is_show"  @click="close" class="back_cover"></div>
    </div>
</template>

<script>
import { Popover } from 'element-ui'

export default {
    components: {
        [Popover.name]: Popover
    },
    data () {
        return {
            is_show: false
        }
    },
    methods: {
       concat() {
           document.documentElement.style.overflow = 'hidden'
           this.is_show = true
       },
       close() {
           document.documentElement.style.overflow = 'auto'
           this.is_show = false
       }
    }
}
</script>

<style lang="scss" scoped>
    .rj_down_box {
        padding-top: 30px;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        >div {
            flex: 1;
            margin-right: 18px;
            &:last-child {
                margin-right: 0;
            }
        }
        .rj_down {
            cursor: pointer;
            background-color: #fff;
            border-radius: 6px;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            &::before {
                content: '';
                width: 0;
                height: 0;
                border-top: 80px solid rgb(250, 62, 62);
                border-right: 80px solid transparent;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
            &::after {
                content: '免费';
                color: #fff;
                font-size: 16px;
                line-height: 16px;
                position: absolute;
                top: 18px;
                left: 8px;
                z-index: 2;
            }
            >img {
                margin-top: 30px;
                margin-bottom: 16px;
                width: 96px;
                height: 99px;
            }
            >div {
                font-size: 20px;
            }
            .opera_box {
                margin-top: 44px;
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                >span, >a {
                    width: 160px;
                    height: 42px;
                    color: #3EB234;
                    font-size: 16px;
                    background-color: #E9FAEC;
                    border-radius: 6px;
                    text-decoration: none;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    >img {
                        margin-right: 16px;
                        width: 26px;
                        height: 26px;
                    }
                }
                .ml {
                    margin-left: 20px;
                }
            }
        }
    }
    .rj_cont {
        width: 100%;
        background-color: #fff;
        .rj_title {
            padding-left: 20px;
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 20px;
            box-sizing: border-box;
            border-bottom: 1px solid #E3E3E5;
        }
        .rj_main {
            padding: 0 20px;
            .rj_tab {
                width: 100%;
                height: 44px;
                font-size: 20px;
                color: #A1A1A1;
                background-color: #F9F9F9;
                display: flex;
                >div {
                    width: 120px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .rj_text {
                margin-bottom: 36px;
                font-size: 14px;
                color: #282828;
                >div {
                    &:first-child {
                        margin-bottom: 10px;
                        font-weight: bold;
                    }
                }
            }
            .rj_img_text {
                padding-bottom: 26px;
                text-align: center;
                width: 100%;
                >img {
                    width: 100%;
                    height: auto;
                }
                >span {
                    display: inline-block;
                    margin-top: 13px;
                    font-size: 16px;
                }
            }
        }
    }
    .tab_active {
        background-color: #fff;
        color: #FA3E3E;
        border-top: 3px solid #FA3E3E;
        box-sizing: border-box;
    }
    .concat_box {
        width: 400px;
        height: auto;
        border-radius: 6px;
        overflow: hidden;
        background-color: #fff;
        position: fixed;
        left: 50%;
        top: 50%;
        margin-left: -200px;
        transform: translateY(-50%);
        z-index: 99;
        .title {
            padding: 0 16px;
            font-size: 20px;
            height: 46px;
            border-bottom: 1px solid #ddd;
            background-color: #FA3E3E;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            >span {
                width: 40px;
                text-align: right;
                font-size: 30px;
                cursor: pointer;
            }
        }
        .cont {
            padding: 20px 16px;
            .concat_row {
                padding-left: 4px;
                height: 40px;
                display: flex;
                align-items: center;
                >span {
                    margin-right: 10px;
                }
            }
        }
    }
    .back_cover {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: 0.4);
        z-index: 98;
    }
</style>